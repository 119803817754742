import React, { useState } from "react";
import { Navbar, Button, NavbarBrand, NavbarContent, NavbarItem, Dropdown, DropdownMenu, DropdownTrigger, DropdownItem, Image, Avatar } from "@nextui-org/react";
import { router } from "@inertiajs/react";
import { AdjustmentsHorizontalIcon, ArrowLeftStartOnRectangleIcon, ArrowUpIcon, AtSymbolIcon, Bars4Icon, BoldIcon, BoltIcon, BuildingLibraryIcon, ChatBubbleLeftEllipsisIcon, ChatBubbleLeftRightIcon, ChevronDoubleDownIcon, ChevronDownIcon, DocumentCurrencyDollarIcon, EnvelopeIcon, FlagIcon, NewspaperIcon, QuestionMarkCircleIcon, QueueListIcon, UserCircleIcon, UserGroupIcon, UsersIcon } from "@heroicons/react/24/outline";
import { } from "antd";
import { FcCurrencyExchange } from "react-icons/fc";
import { MdCurrencyExchange } from "react-icons/md";
import { FaTelegramPlane } from "react-icons/fa";
import { FaAnglesUp } from "react-icons/fa6";

declare global {
    interface Window {
        Chatra: any;
    }
}

const Footer: React.FC = () => {
    const [isEmailFormSubmitted, setEmailFormSubmitted] = useState(false);

    const handleEmailFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setEmailFormSubmitted(true); // Устанавливаем состояние как отправлено
    };
    const openChatra = () => {
        if (window.Chatra) {
            window.Chatra('openChat', true);
        } else {
            console.error('Chatra is not loaded yet.');
        }
    };

    const openTelegram = () => {
        window.open("https://t.me/discoexchange", "_blank");
    };

    const scrollToExchange = () => {
        if (window.location.pathname === "/") {
            document.getElementById("exchange")?.scrollIntoView({ behavior: "smooth" });
        } else {
            router.visit("/#exchange");
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <>
            <div className="fixed bottom-4 left-4 flex flex-col space-y-3">
                <Button isIconOnly onClick={openTelegram} color="primary" variant="shadow" className="p-2 rounded-full w-12 h-12"><FaTelegramPlane className="size-7" /> </Button>
                <Button isIconOnly onClick={scrollToExchange} color="primary" variant="shadow" className="p-2 rounded-full w-12 h-12"><MdCurrencyExchange className="size-7" /> </Button>
                <Button isIconOnly onClick={scrollToTop} color="primary" variant="shadow" className="p-2 rounded-full w-12 h-12"><FaAnglesUp className="size-7" /></Button>
            </div>
            <section className="relative py-10 overflow-hidden sm:py-16 lg:py-24 xl:py-32">
                <div className="absolute inset-0">
                    <img className="object-cover w-full h-full md:object-left md:scale-150 md:origin-top-left opacity-90" src="/assets/img/block.avif" alt="" />
                </div>

                <div className="absolute inset-0 hidden bg-gradient-to-r md:block from-blue-600 to-transparent"></div>

                <div className="absolute inset-0 block bg-white/60 md:hidden"></div>

                <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                    <div className="text-center md:w-2/3 lg:w-1/2 xl:w-1/3 md:text-left">
                        <h2 className="text-3xl font-bold leading-tight text-black lg:text-white sm:text-4xl lg:text-5xl">AML AI</h2>
                        <p className="mt-4 text-base text-zinc-800 lg:text-gray-200">Искуственный интелект сам сканирует и анализирует транзакции на предмет мошеничиства, отмыва и прочих незаконных действий</p>

                        <form onSubmit={handleEmailFormSubmit}>
                            <div className="mt-8 lg:mt-12">
                                <div className="flex flex-col items-center sm:flex-row sm:justify-center">
                                    <div className="flex-1 w-full min-w-0 px-4 sm:px-0">
                                        <div className="relative text-gray-400 focus-within:text-gray-600">
                                            <label className="sr-only" htmlFor="email">Email</label>
                                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                                </svg>
                                            </div>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                placeholder="Укажите вашу почту"
                                                className="block w-full py-4 pl-10 pr-4 text-base text-black placeholder-gray-500 transition-all duration-200 border-gray-200 rounded-md sm:rounded-r-none caret-blue-600 focus:border-blue-600 focus:ring-1 focus:ring-blue-600"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <Button type="submit" className="inline-flex items-center h-full justify-center flex-shrink-0 w-auto px-4 py-4 mt-4 font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md sm:mt-0 sm:rounded-l-none sm:w-auto hover:bg-blue-700 focus:bg-blue-700">
                                        Получить консультацию
                                    </Button>
                                </div>
                            </div>

                            {isEmailFormSubmitted && (
                                <p className="mt-4 text-center text-green-300 font-semibold">
                                    Успешно отправлено
                                </p>
                            )}
                        </form>
                    </div>
                </div>
            </section>

            <div className="w-full min-h-screen flex items-center justify-center bg-zinc-800">
                <div className="md:w-2/3 w-full px-4 text-white flex flex-col">
                    <div className="w-full text-7xl font-bold">
                        <h1 className="w-full md:w-2/3">Лучшие курсы. Высокая защита
                        </h1>
                    </div>

                    <div className="flex flex-col">
                        <div className="flex mt-24 mb-12 flex-row items-center justify-between">
                            <div className="">
                                <Image src="/assets/img/ds_log.png" className="w-16 h-auto mr-2" />
                            </div>
                            <a href="/" className="hidden md:block cursor-pointer text-gray-600 hover:text-white uppercase">Главная</a>
                            <a href="/faq" className="hidden md:block cursor-pointer text-gray-600 hover:text-white uppercase">FAQ</a>
                            <a href="/vacancies" className="hidden md:block cursor-pointer text-gray-600 hover:text-white uppercase">Новости</a>
                            <a href="/contacts" className="hidden md:block cursor-pointer text-gray-600 hover:text-white uppercase">Связаться с нами</a>

                        </div>
                        <hr className="border-gray-600" />
                        <p className="w-full text-center my-12 text-gray-600">Copyright © 2023-2024 Disco Exchange</p>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Footer;
