import './bootstrap';
import '../css/app.css';

import { createRoot, hydrateRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { NextUIProvider } from '@nextui-org/react'; // Импортируем NextUIProvider
import { ThemeProvider as NextThemesProvider } from 'next-themes'; // Для поддержки тёмных и светлых тем
import NavbarComponent from './Components/Navbar'; // Импортируйте ваш Navbar
import Footer from './Components/Footer'; // Импортируйте ваш Navbar
import { PageProps } from './types'; // Импортируйте тип PageProps

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({ el, App, props }) {
        const { auth } = props.initialPage.props as unknown as PageProps; // Типизация props

        const user = auth?.user;
        const currentRoute = props.initialPage.url;

        if (import.meta.env.DEV) {
            createRoot(el).render(
                <NextUIProvider>
                    <NextThemesProvider attribute="class" defaultTheme="light">
                        <main className="light text-foreground">
                            <NavbarComponent user={user} />
                            <App {...props} />
                            <Footer />
                        </main>
                    </NextThemesProvider>
                </NextUIProvider>
            );
            return;
        }

        hydrateRoot(
            el,
            <NextUIProvider>
                <NextThemesProvider attribute="class" defaultTheme="light">
                    <main className="light text-foreground">
                        <NavbarComponent user={user} />
                        <App {...props} />
                        <Footer />
                    </main>
                </NextThemesProvider>
            </NextUIProvider>
        );
    },
    progress: {
        color: '#4B5563',
    },
});
