import React, { useState } from "react";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Image, Modal, Input, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure, Textarea } from "@nextui-org/react";
import { Link, router } from "@inertiajs/react";
import { Dropdown, Menu, message, Button } from "antd"; // Импортируем компоненты из antd
import { AdjustmentsHorizontalIcon, ArrowPathIcon, AtSymbolIcon, BoltIcon, BuildingLibraryIcon, ChatBubbleLeftRightIcon, ChevronDownIcon, HomeIcon, QueueListIcon, UserCircleIcon, UsersIcon } from "@heroicons/react/24/outline";
import { DownOutlined } from '@ant-design/icons'; // Импортируем иконки из antd
import { FiUser } from "react-icons/fi";
import { FaAnglesDown } from "react-icons/fa6";
import { CgMenuGridO, CgMenuGridR } from "react-icons/cg";
import { BsCurrencyExchange } from "react-icons/bs";
import { FcAssistant, FcCurrencyExchange, FcFaq, FcHighPriority, FcHome, FcOrgUnit, FcPortraitMode, FcRules, FcSimCardChip } from "react-icons/fc";

declare global {
    interface Window {
        Chatra: any;
    }
}

interface NavbarComponentProps {
    user: {
        name: string;
        email: string;
    } | null;
}

const NavbarComponent: React.FC<NavbarComponentProps> = ({ user }) => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [complaint, setComplaint] = useState("");
    const [email, setEmail] = useState(user ? user.email : "");

    const handleNavigation = (url: string) => {
        if (url.startsWith("http")) {
          window.open(url, "_blank");
        } else {
          router.visit(url);
        }
      };


    const openChatra = () => {
        if (window.Chatra) {
            window.Chatra('openChat', true);
        } else {
            console.error('Chatra is not loaded yet.');
        }
    };

    // Обработчик клика по меню с указанием явного типа для key
    const handleMenuClick = ({ key }: { key: string }) => {
        message.info(`Вы кликнули по пункту меню ${key}`);
    };

    return (
        <>
            <Navbar shouldHideOnScroll isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen} className="py-2">
                {/* Логотип */}
                {/* Дропдаун профиля */}
                <Dropdown
                    overlay={
                        <Menu onClick={handleMenuClick} items={[
                            user ?
                                { label: 'История обменов', icon: <FcOrgUnit className="size-6" />, key: 'dashboard', onClick: () => handleNavigation("/dashboard") } :
                                { label: 'Вход/Регистрация', icon: <FcPortraitMode className="size-6" />, key: 'login', onClick: () => handleNavigation("/login") },
                            { type: 'divider' },
                            { label: 'Главная', icon: <FcHome className="size-6" />, key: 'home', onClick: () => handleNavigation("/") },
                            { label: 'Новости', icon: <FcRules className="size-6" />, key: 'news', onClick: () => handleNavigation("/vacancies") },
                            { label: 'FAQ', icon: <FcFaq className="size-6" />, key: 'faq', onClick: () => handleNavigation("/faq") },
                            { label: 'Связаться с нами', icon: <FcAssistant className="size-6" />, key: 'contact', onClick: () => handleNavigation("/contacts") },
                            { type: 'divider' },
                            { label: 'KYC & AML', icon: <FcSimCardChip className="size-6" />, key: 'kyc', onClick: () => handleNavigation("https://disco-exchange.gitbook.io/disco-exchange/aml-kyc-ctf") },
                            { label: 'Правила Сервиса', icon: <FcSimCardChip className="size-6" />, key: 'terms', onClick: () => handleNavigation("https://disco-exchange.gitbook.io/disco-exchange") },
                            { label: 'Политика Конфиденциальности', icon: <FcSimCardChip className="size-6" />, key: 'privacy', onClick: () => handleNavigation("https://disco-exchange.gitbook.io/disco-exchange/politika-konfidencialnosti") },
                            { type: 'divider' },
                            { label: 'Не работает сайт', icon: <FcHighPriority className="size-6" />, key: 'modal', onClick: onOpen },
                        ]} />
                    }
                >
                    <Button className="font-semibold border-2 border-blue-500" size="large" icon={<CgMenuGridR className="size-6" />}>
                        {user?.name || ""}
                    </Button>
                </Dropdown>
                {/* Основная навигация */}
                <NavbarContent className="hidden sm:flex gap-4" justify="center">


                    {/* Меню компании */}
                    <Link href="/">
                        <Button className="font-semibold" type="text" icon={<FcCurrencyExchange className="size-6" />}>
                            Главная
                        </Button>
                    </Link>

                    {/* Полезное меню */}
                    <Link href="/faq">
                        <Button className="font-semibold" type="text" icon={<FcFaq className="size-6" />}>
                            FAQ
                        </Button>
                    </Link>

                    <NavbarItem>
                        <NavbarItem>
                            <Button
                                icon={<FcSimCardChip className="size-6" />}
                                className="font-semibold text-inherit"
                                onClick={() => window.open("https://disco-exchange.gitbook.io/disco-exchange/aml-kyc-ctf", "_blank")}
                            >
                                KYC & AML
                            </Button>
                        </NavbarItem>
                    </NavbarItem>
                </NavbarContent>

                {/* Контент справа */}
                <NavbarContent justify="end">
                    <Link href="/">
                        <Image src="/assets/img/ds_log.png" className="w-16 h-auto mr-2" />
                    </Link>
                </NavbarContent>
            </Navbar>

            <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Отправить жалобу</ModalHeader>
                            <ModalBody>
                                <Input
                                    label="Ваш Email"
                                    type="email"
                                    value={email}
                                    variant="underlined"
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="custom-input"
                                    required
                                />
                                <Textarea
                                    label="Характер проблемы"
                                    className="mt-1 block w-full custom-input"
                                    value={complaint}
                                    variant="underlined"
                                    onChange={(e) => setComplaint(e.target.value)}
                                    required
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" onClick={onClose}>
                                    Закрыть
                                </Button>
                                <Button color="primary" onClick={onClose}>
                                    Отправить
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default NavbarComponent;
